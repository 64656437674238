import React, { useState } from 'react';
// Material UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// MUI Icons
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
//  React Router Dom
import { Link, useLocation } from "react-router-dom";

function TopBar() {

  const location = useLocation();
  const currentTab = location.pathname.split("/")[1] ? 1 : 0

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography variant="h6" noWrap
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1,
                fontWeight: 700,
                color: 'inherit'
              }}>
              WCL20
            </Typography>
            <Tabs
              value={currentTab}
              sx={{ display: { xs: 'none', md: 'flex' }}}
              TabIndicatorProps={{ style: {transition: 'none'} }}
            >
              <Tab label="About" component={Link} value={0} to="/" />
              <Tab label="Projects" component={Link} value={1} to="/projects" />
            </Tabs>

            <Box component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" color="inherit" onClick={() => setIsDrawerOpen((prevState) => !prevState)}>
                <MenuIcon />
              </IconButton>
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography variant="h5" noWrap
              sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, fontWeight: 700, color: 'inherit' }}
            >
              WCL20
            </Typography>

          </Toolbar>
        </Container>
      </AppBar>
      <Box component="div" onClick={() => setIsDrawerOpen(false)}>
        <Drawer
          anchor="left"
          variant="temporary"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          ModalProps={{ keepMounted: false }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '60vw' },
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/">
                <ListItemText primary="ABOUT" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/projects">
                <ListItemText primary="PROJECTS" />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </>
  );
}

export default TopBar;
