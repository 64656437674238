import React, { Suspense, lazy } from 'react';
// Material UI Theme
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
//  React Router Dom
import { Route, Routes } from "react-router-dom";
// components
import TopBar from './components/TopBar';
// Themes
import { DarkTheme } from './themes/Dark';
const theme = createTheme(DarkTheme);

// Pages
const CodeGenerator = lazy(() => import('./pages/ProjectsPage/CodeGenerator'));
const ErrorPage = lazy(() => import('./pages/error'));
const FramerMotionTutorial = lazy(() => import('./pages/ProjectsPage/FramerMotionTutorial'));
const HomePage = lazy(() => import('./pages/HomePage'));
const NotebookPage = lazy(() => import('./pages/NotebookPage'));
const NotebooksPage = lazy(() => import('./pages/NotebooksPage'));
const PointCloudGenerator = lazy(() => import('./pages/ProjectsPage/PointCloudGenerator'));
const ProjectsPage = lazy(() => import('./pages/ProjectsPage'));
const WorldAlbum = lazy(() => import('./pages/ProjectsPage/WorldAlbum'));

function App() {

  const AppBarOffset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
      <AppBarOffset />
      <Box component="div" sx={{  minHeight: 'calc(100vh - 64px)' }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/:id" element={<NotebooksPage />} />
            <Route path="/project/codegenerator/*" element={<CodeGenerator />} />
            <Route path="/project/framermotiontutorial/*" element={<FramerMotionTutorial />} />
            <Route path="/project/pointcloudgenerator/*" element={<PointCloudGenerator />} />
            <Route path="/project/worldalbum" element={<WorldAlbum />} />
            <Route path="/project/:id" element={<NotebookPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </Box>
    </ThemeProvider>
  )
}

export default App;
